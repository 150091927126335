import * as t from '../mutations'
import axiosClient from '@/http'

import {
  state as BaseState,
  getters as BaseGetters,
  actions as BaseActions,
  mutations as BaseMutations,
} from '@/store/base'
import * as Cookies from 'js-cookie'

const STATE = {
  ...BaseState,
  namespace: 'publicApiDocumentation',
  apiDocsMeta: [],
  apiDocsMetaNormalized: {},
  docsAdminToolsVisible: false,
}

const GETTERS = {
  ...BaseGetters,
  apiDocsMeta: state => state.apiDocsMeta,
  apiDocsMetaNormalized: state => {
    const normalizedObj = {}

    if (Array.isArray(state.apiDocsMeta)) {
      state.apiDocsMeta.forEach((doc => {
        if (!normalizedObj[doc.name]) normalizedObj[doc.name] = {}
        normalizedObj[doc.name][doc.slug] = doc.id
      }))
    }

    return normalizedObj
  },
  docsAdminToolsVisible: _state => {
    return Cookies.get('admin') === 'logged_in_as_client' &&
      sessionStorage.getItem('admin-logged-in')
  },
}

const ACTIONS = {
  ...BaseActions,
  async fetchApiDocsMeta({ commit }) {
    const response = await axiosClient.get('client/public_api_docs')

    if (response.data.success) {
      commit(t.SET_API_DOCS_META, response.data.result)
    }
  },
  async fetchApiDocsContentById({ _commit }, id) {
    const response = await axiosClient.get(`client/public_api_docs/${id}`)

    if (response.data.success) {
      return response.data.result.content
    }
  },
}

const MUTATIONS = {
  ...BaseMutations,
  [t.SET_API_DOCS_META](state, records) {
    state.apiDocsMeta = records
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
