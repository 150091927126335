import mediaTypes from '@/lib/mediaTypes'
import * as t from '@/store/mutations'
const { jsonApi } = mediaTypes

const initialState = () => ({
  stages: [],
  stage: {},
  steps: [],
  accountsStage: {},
  accountsStages: [],
  slide: {},
})

const STATE = initialState()

const GETTERS = {
  stages: state => state.stages,
  stage: state => state.stage,
  steps: state => state.steps,
  accountsStage: state => state.accountsStage,
  accountsStages: state => state.accountsStages,
  slide: state => state.slide,
  isFirstSlide: state => state.slide.position === 0 && state.steps.find(s => s.id === state.slide.step.id)?.position === 0,
  isLastSlide: state => state.slide.is_last,
  requestConfig: (_state, _getters, _rootState, rootGetters) => ({
    baseURL: process.env.VUE_APP_STAGE_MANAGER_URL,
    headers: {
      Authorization: `Bearer ${rootGetters['session/getToken']}`,
    },
  }),
  defaultParams: (_state, _getters, _rootState, rootGetters) => ({
    // TODO: This should be in the JWT token, but fine for now since we auth the user based off the
    // account_id and accounts aren't current used across multiple websites.
    reseller_id: rootGetters['account/websiteId'],
    // Only allow admins to pass these values.
    ...(rootGetters['session/isAdminImpersonatingClient'] && {
      account_id: rootGetters['account/actualAccountID'],
      impersonating: true,
    }),
  }),
  accountsStageTrackableCompanyId: (state) => {
    return state.accountsStage.trackable_type === 'V2api::Company'
      ? state.accountsStage.trackable_id
      : null
  },

  accountsStageTrackableAccountId: (state) => {
    return state.accountsStage.trackable_type !== 'V2api::Company'
      ? state.accountsStage.trackable_id
      : null
  },
}

const ACTIONS = {
  // GET requests
  async getAccountsStages({ getters }, { id = null, ...jsonApiConfig }) {
    return await jsonApi.get(`/api/v1/stage_manager/accounts_stages${id ? `/${id}` : ''}`, getters.requestConfig, {
      ...jsonApiConfig,
      ...getters.defaultParams,
    })
  },
  async getAccountsStage({ dispatch }, { id, ...jsonApiConfig }) {
    return await dispatch('getAccountsStages', { id, ...jsonApiConfig })
  },

  // Async Getters and Setters
  async getAndSetAccountsStages({ commit, getters, dispatch }, { clear = true, ...jsonApiConfig }) {
    const response = await dispatch('getAccountsStages', jsonApiConfig)

    dispatch('setContext', { include: jsonApiConfig.include, response })
    const accountsStages = clear ? response : [...getters.accountsStages, ...response]
    commit(t.SET_ACCOUNTS_STAGES, accountsStages)
  },
  async getAndSetAccountsStage({ dispatch, commit }, { id, ...jsonApiConfig }) {
    const response = await dispatch('getAccountsStage', { id, ...jsonApiConfig })
    commit(t.SET_ACCOUNTS_STAGE, response)
    dispatch('setContext', { include: jsonApiConfig.include, response })
  },

  // PATCH requests
  async updateAccountsStage({ commit, getters, dispatch }, { id, data = {}, ...jsonApiConfig }) {
    const response = await jsonApi.patch(`/api/v1/stage_manager/accounts_stages/${id}`, getters.requestConfig, {
      data: jsonApi.serialize({ id, type: 'accounts_stage', data }),
      ...jsonApiConfig,
      ...getters.defaultParams,
    })

    commit(t.SET_ACCOUNTS_STAGE, response)
    dispatch('setContext', { include: jsonApiConfig.include, response })
  },

  // Sets the rest of the context for the store based on what was included in the response.
  setContext({ commit }, { include = [], response }) {
    if (include.includes('stage')) {
      if (Array.isArray(response)) {
        commit(t.SET_STAGES, response.map(accounts_stage => accounts_stage.stage))
      } else {
        commit(t.SET_STAGE, response.stage)
      }
    }

    if (include.includes('stage.steps')) {
      commit(t.SET_STEPS, response.stage.steps)
    }

    if (include.includes('current_slide')) {
      commit(t.SET_SLIDE, response.current_slide)
    }
  },
}

const MUTATIONS = {
  [t.SET_STAGES](state, stages) {
    state.stages = stages
  },
  [t.SET_STAGE](state, stage) {
    state.stage = stage
  },
  [t.SET_STEPS](state, steps) {
    state.steps = steps
  },
  [t.SET_ACCOUNTS_STAGE](state, accountsStage) {
    state.accountsStage = accountsStage
  },
  [t.SET_ACCOUNTS_STAGES](state, accountsStages) {
    state.accountsStages = accountsStages
  },
  [t.SET_SLIDE](state, slide) {
    state.slide = slide
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
