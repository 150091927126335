import Vue from 'vue'
import * as t from '../mutations'
import http from '@/http'

import {
  state as BaseState,
  getters as BaseGetters,
  actions as BaseActions,
  mutations as BaseMutations,
} from '@/store/base'

import _ from 'lodash'

const STATE = {
  ...BaseState,
  namespace: 'orders',
  orders: {},
  ordersLoaded: false,
  error: null,
}

const GETTERS = {
  ...BaseGetters,
  find: state => id => state.orders[id],
  orders: state => Object.values(state.orders),
  ordersLoaded: state => state.ordersLoaded,
  error: state => state.error,
}

const ACTIONS = {
  ...BaseActions,
  async loadIfNotAvailableById({ dispatch, getters }, { id, params }) {
    if (!getters.find(id)) {
      await dispatch('loadById', { id, params })
    }
  },

  async loadById({ commit }, { id, params }) {
    commit(t.LOAD_ORDERS_START)

    try {
      const order = await http.get(`client/orders/${id}`, { params })

      commit(t.LOAD_ORDERS_FINISHED, [order.data.result])
    } catch (error) {
      commit(t.LOAD_ORDERS_FAILED, error)
    }
  },

  async updateItemData(_, itemData) {
    const promises = Object.entries(itemData).map(([id, data]) => {
      return http.patch(`client/order_items/${id}`, { order_item: { data: data } })
    })
    await Promise.all(promises)
  },

  updateItemDataComplete(_, itemData) {
    Object.entries(itemData).forEach(async ([id, data]) => {
      await http.patch(`client/order_items/${id}`, { order_item: { data: data, completed: true } })
    })
  },
}

const MUTATIONS = {
  ...BaseMutations,
  [t.LOAD_ORDERS_START](state) {
    state.ordersLoaded = false
  },

  [t.LOAD_ORDERS_FINISHED](state, orders) {
    orders.forEach(c => Vue.set(state.orders, c.id, c))

    state.ordersLoaded = true
  },

  [t.LOAD_ORDERS_FAILED](state, error) {
    state.error = error
  },
}

export const currentTimelineFor = orderItem => {
  const type = _.kebabCase(orderItem.product.filingName)
  const timeline = buildTimelineFor(orderItem)
  const requiresAttention = [
    'held',
    'needs-attention',
    'awaiting-client-input',
    'needs-verification',
    'name-not-available',
    'not-filed-in-state',
    'waiting-on-cc-auth',
    'waiting-on-information',
    'waiting-on-cogs',
    'waiting-on-signature',
    'unpaid',
  ].includes(orderItem.status)
  const unhandled = !orderItem.handler

  const submitted = ['submitted-to-state', 'submitted'].includes(orderItem.status)
  const completed = orderItem.status == 'completed'

  if (requiresAttention) {
    timeline[0].components = [
      String(timeline[0].components[0]).mapping({
        ':status': orderItem.status,
      }),
    ]
    return timeline
  }
  timeline[0].completed = [timeline[0].components[0]]
  if (!unhandled) {
    timeline[1].completed = [timeline[1].components[0]]
    switch (type) {
      case 'form-a-company':
        timeline[2].completed = [timeline[2].components[0]]
        if (submitted) {
          timeline[3].completed = [timeline[3].components[0]]
          timeline[4].completed = [timeline[4].components[0]]
        }
        if (completed) {
          timeline[3].completed = [timeline[3].components[0]]
          timeline[4].completed = [timeline[4].components[0]]
          timeline[5].completed = [timeline[5].components[0]]
          // timeline[6].completed = [timeline[6].components[0]]
        }
        break

      case 'amendment':
      case 'boc-3':
      case 'compliance-filing':
      case 'certified-copy':
        break

      case 'tax-id':
        timeline[2].completed = [timeline[2].components[0]]
        break

      default:
        break
    }
    // if (type == 'tax-id') {
    //   timeline[2].completed.push(timeline[2].components[0])
    //   return timeline
    // } else if (submitted) {
    //   timeline[3].completed.push(timeline[3].components[0])
    // } else if (completed) {
    //   timeline.forEach((step) => {
    //     if (step.completed.length == 0) {
    //       step.completed.push(step.components[0])
    //     }
    //   })
    // }
  }
  return timeline
}

const buildTimelineFor = orderItem => {
  const steps = []
  let type = _.kebabCase(orderItem.product.filingName)
  let filingStepDescriptions = [
    ':company, your filing has been marked :status please visit orders requiring your attention for next steps',
  ]
  switch (type) {
    case 'form-a-company':
      filingStepDescriptions.push(
        ':company, your filing has been sent to the filers queue and will be assigned shortly',
        ':company, your filing has been assigned to :filer, they will begin working on your filing very soon!',
        ':company, :filer has started processing your order and begun a name search with the state.',
        ':company, your filing has been submitted to the state and is in progress. The filing time for this is usually about :stateFilingTime.',
        ':company, your filing has been approved by the state, documents will be uploaded momentarily.',
        ':company, your filing documents have been uploaded to your account and your filing service is completed.'
      )
      break
    case 'tax-id':
      filingStepDescriptions.push(
        ':company, your tax ID filing has been sent to the filers queue and will be assigned shortly.',
        ':company, your filing has been assigned to :filer, they will begin working on your filing very soon!',
        ':company, :filer has started processing your order with the IRS.'
      )
      break
    case 'dissolve-a-company':
      filingStepDescriptions.push(
        ':company, your filing has been sent to the filers queue and will be assigned shortly.',
        ':company, your filing has been assigned to :filer, they will begin working on your filing very soon!',
        ':company, your filing has been submitted to the state and is in progress. The filing time for this is usually about :stateFilingTime.',
        ':company, your filing has been approved by the state, documents will be uploaded momentarily.',
        ':company, your filing documents have been uploaded to your account and your filing service is completed, be sure to cancel your registered agent service within your account if you no longer need it.'
      )
      break
    case 'amendment':
    case 'boc-3':
    case 'compliance-filing':
    case 'certified-copy':
    default:
      filingStepDescriptions.push(
        ':company, your filing has been sent to the filers queue and will be assigned shortly.',
        ':company, your filing has been assigned to :filer, they will begin working on your filing very soon!',
        ':company, your filing has been submitted to the state and is in progress. The filing time for this is usually about :stateFilingTime.',
        ':company, your filing has been approved by the state, documents will be uploaded momentarily.',
        ':company, your filing documents have been uploaded to your account and your filing service is completed.'
      )
      break
  }
  filingStepDescriptions.forEach(description => {
    steps.push({
      name: type,
      completed: [],
      components: [description],
    })
  })

  return steps
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
