export const OPERATING_STATUSES = {
  ACTIVE:                    'active',
  TRIAL_ACTIVE:              'trial-active',
  AWAITING_CLIENT_INPUT:     'awaiting-client-input',
  VERIFICATION_NEEDED:       'verification-needed',
  NEW:                       'new',
  CANCEL_REQUESTED:          'cancel-requested',
  WAITING_ON_INFORMATION:    'waiting on information',
  WAITING_ON_PAYMENT:        'waiting on payment',
}

export const SERVICE_TYPES = {
  BLANKET_AGENT:          'blanket-agent',
  CALL_FORWARDING:        'call-forwarding',
  COMPLIANCE:             'compliance',
  INTERNAL_USE:           'internal-use',
  LOCAL_DC_AGENT:         'local-dc-agent',
  MAIL_FORWARDING:        'mail-forwarding',
  REGISTERED_AGENT:       'registered-agent',
  VEHICLE_REGISTRATIONS:  'vehicle-registration',
  LAW_ON_CALL:            'law-on-call',
  MONITORING:             'monitoring',
  BUSINESS_EMAIL:         'business-email',
  DOMAIN:                 'business-domain',
  WEB_HOSTING:            'business-website-hosting',
  SSL_TLS:                'domain-ssl',
  VIRTUAL_PHONE:          'virtual-phone',
}

export const SERVICE_TYPES_MULTI_PURCHASABLE = {
  CALL_FORWARDING:        'call-forwarding',
  MAIL_FORWARDING:        'mail-forwarding',
}

export const AUTOPAY_SELECTABLE_STATUSES = {
  ACTIVE:                 'active',
  AWAITING_CLIENT_INPUT:  'awaiting-client-input',
  VERIFICATION_NEEDED:    'verification-needed',
  WAITING_ON_INFORMATION: 'waiting on information',
  WAITING_ON_PAYMENT:     'waiting on payment',
  CANCEL_REQUESTED:       'cancel-requested',
  NEW:                    'new',
  TRIAL_ACTIVE:           'trial-active',
}

// This tells us what to do with the call when it comes in. Forward to...
export const CALL_FORWARD_TYPES = {
  BROWSER_ONLY:         'browser-only',
  NUMBER_ONLY:          'number-only',
  BROWSER_AND_NUMBER:   'browser-and-number',
}

// "Forward as". This describes what we display on caller id when we forward calls.
export const CALL_FORWARD_AS_TYPES = {
  INBOUND_NUMBER:         'inbound-number',
  FORWARDING_NUMBER:      'forwarding-number',
}

// later we will add several options
export const RINGTONES = {
  DISABLED: 'disabled',
  DEFAULT:  'default',
}

export const LLC_CORP_ENTITY_IDS = [
  '74c772c3-7381-41a4-a50a-0160da5f1e10',
  '3803e681-f9fe-4ccc-8d62-ed877d119181',
]

export const FILINGS_PACKAGED_WITH_RA_SERVICE = [
  'form a company',
  'register a company',
  'domestication',
  'domesticating in',
  'vehicle form a company',
  'change registered agent',
]

export const FILINGS_PACKAGED_WITH_RENEWAL_SERVICE = [
  'form a company',
  'register a company',
]

// FILE-3913: Disabling auto enrollment for 'form a company', likely temporary
export const FILINGS_PACKAGED_WITH_BOI_FILING = [
  // 'form a company',
]

export const ENTITY_TYPES_WITHOUT_BOI_FILING = [
  'Individual',
  'Sole Proprietorship',
  'Doing Business as Name',
]

export const MULTI_JURISDICTION_FILINGS = [
  'register a company',
  'change registered agent',
  'assumed name',
  'trade name',
]

export const MonitoringIdentityType = {
  TRADEMARK: [0,1,2],
  DOMAIN: 3,
  KEYWORD: 4,
  COMPANY: 5,
}

export const GENERAL_OVERSEAS_SHIPPING_FEE_NAMES = [
  'general overseas delivery',
  'general overseas shipping',
  'general overseas shipping delivery',
  'overseas delivery',
]

export const PRODUCTS_WITH_SHIPPING= [
  'certificates - 10',
  'certificates - 100',
  'certificates - 20',
  'certificates - 40',
  'certificates - 60',
  'certificates - 80',
  'corporate book',
  'corporate book and seal',
  'corporate seal',
  'embossing seal',
  'gobook',
  'gobook with embossing seal',
  'notary stamp',
  'traditional binder/slipcase (book only)',
  'traditional corp/llc kit (includes embossing seal)',
]

export const DOMESTIC = [
  'us',
  'usa',
  'united states',
  'united states of america',
]

export const VEHICLE_FILINGS = {
  VEHICLE_FORM_A_COMPANY:           'vehicle form a company',
  VEHICLE_ORGANIZATIONAL_PLATES:    'vehicle organizational plates',
  VEHICLE_REGISTRATION:             'vehicle registration',
  VEHICLE_RENEWAL:                  'vehicle renewal',
  VEHICLE_REPLACEMENT_PLATES:       'vehicle replacement plates',
  VEHICLE_REPLACEMENT_REGISTRATION: 'vehicle replacement registration',
  VEHICLE_REPLACEMENT_TITLE:        'vehicle replacement title',
  VEHICLE_TEMPORARY_PLATES:         'vehicle temporary plates',
}

export const VEHICLE_STATUSES = {
  NEW:                        'new',
  AWAITING_CLIENT_INPUT:      'awaiting-client-input',
  VEHICLE_DOCUMENTS_RECEIVED: 'vehicle-documents-received',
  NOT_READY_TO_FILE:          'not-ready-to-file',
  SUBMITTED_TO_STATE:         'submitted-to-state',
  IN_PROGRESS:                'in-progress',
  HELD:                       'held',
  WAITING_ON_ADDRESS:         'waiting-on-address',
  READY_TO_MAIL:              'ready-to-mail',
  COMPLETED:                  'completed',
}

export const PENDING_FILINGS = {
  AMENDMENT: 'amendment',
  APOSTILLE: 'apostille',
  ASSUMED_NAME: 'assumed name',
  BENEFICIAL_OWNERSHIP_AMENDMENT_REPORT: 'beneficial ownership amendment report',
  BENEFICIAL_OWNERSHIP_INFORMATION_REPORT: 'beneficial ownership information report',
  CHANGE_OF_AGENT: 'change of agent',
  CERTIFICATE_OF_GOOD_STANDING: 'certificate of good standing',
  CERTIFIED_COPY: 'certified copy',
  COMPLIANCE: 'compliance filing',
  DISSOLVE_A_COMPANY: 'dissolve a company',
  FORM_A_COMPANY: 'form a company',
  FREE_INITIAL_REPORT: 'free initial report',
  INITIAL_REPORT: 'initial report',
  NO_SSN_TAX_ID: 'no-ssn tax id',
  PUBLICATION: 'publication',
  REGISTER_A_COMPANY: 'register a company',
  REINSTATEMENT: 'reinstatement',
  SSN_TAX_ID: 'ssn tax id',
  S_CORP: 's corp',
  TAX_ID: 'tax id',
  TRADE_NAME: 'trade name',
  VEHICLE_FORM_A_COMPANY: 'vehicle form a company',
  WITHDRAW_A_COMPANY: 'withdraw a company',
}

export const PENDING_STATUSES = {
  AWAITING_CLIENT_INPUT: 'awaiting-client-input',
  BOI_FILED_BY_CLIENT: 'boi-filed-by-client',
  HELD: 'held',
  IN_PROGRESS: 'in-progress',
  INCOMPLETE: 'incomplete',
  NAME_NOT_AVAILABLE: 'name-not-available',
  NEW: 'new',
  NOT_FILED_IN_STATE: 'not-filed-in-state',
  NOT_READY_TO_FILE: 'not-ready-to-file',
  ORDER_VERIFICATION_REQUIRED: 'order-verification-required',
  PENDING_CHANGE_OF_AGENT: 'pending-change-of-agent',
  PENDING_FINAL_REVIEW: 'pending-final-review',
  PENDING_STATE_FORMATION: 'pending-state-formation',
  PENDING_TAX_ID: 'pending-tax-id',
  PENDING_VERIFICATION: 'pending-verification',
  RENEWAL_FILED_BY_CLIENT: 'renewal-filed-by-client',
  REVOKED_IN_STATE: 'revoked-in-state',
  SUBMITTED_TO_IRS: 'submitted-to-irs',
  SUBMITTED_TO_NEWSPAPER: 'submitted-to-newspaper',
  SUBMITTED_TO_STATE: 'submitted-to-state',
  UNPAID: 'unpaid',
  WAITING_ON_CC_AUTH: 'waiting-on-cc-auth',
  WAITING_ON_CERTIFIED_COPIES: 'waiting-on-certified-copies',
  WAITING_ON_COGS: 'waiting-on-cogs',
  WAITING_ON_INFORMATION: 'waiting-on-information',
  WAITING_ON_SIGNATURE: 'waiting-on-signature',
}
